/* Asegúrate de que el body y html tengan un margen y padding de 0 */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Evita el desplazamiento si el contenido se extiende más allá de la pantalla */
}

/* Estilo del header */
.header {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 90px; /* Altura del header */
    display: flex;
    align-items: center;
    border-bottom: 2px solid #024e88; /* Borde inferior */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000; /* Asegura que el header esté encima de otros elementos */
}

/* Estilo para el logo en el header */
.header-logo {
    max-width: 150px;
    height: auto;
    margin-left: 20px; /* Espacio a la izquierda */
}

/* Contenedor principal con un espacio reservado para el header y el footer */
.contenedor {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    padding-top: 80px; /* Espacio para el header */
    padding-bottom: 60px; /* Espacio para el footer */
    position: relative; /* Permite el posicionamiento absoluto de los elementos internos */
}

/* Contenedor de la imagen y texto */
.imagen-angel {
    flex: 1; /* Ocupa el espacio restante */
    position: relative; /* Permite posicionar el texto encima de la imagen */
    background-image: url('../images/angelindepen.jpeg'); /* Ajusta la ruta si es necesario */
    background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
    background-position: center; /* Centra la imagen */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Texto encima de la imagen */
.imagen-angel .texto {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 600px;
    background-color: #61616166;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    font-size: 40px;
    text-align: center;
    z-index: 1; /* Asegura que el texto esté encima de la imagen */
}

/* Footer */
.footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #ffffff;
}

/* Estilo para los enlaces del footer */
.footer-links a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 10px;
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
    .header-logo {
        max-width: 120px;
    }
    
    .imagen-angel .texto {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .header-logo {
        max-width: 100px;
    }

    .imagen-angel .texto {
        font-size: 18px;
    }
}
